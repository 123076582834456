
/******************************************************
 *                                                    *
 *      🚨🚨🚨  DO NOT DELETE THIS CODE  🚨🚨🚨       *
 *                                                    *
 * This code is critical for Tracking                 *
 *                                                    *
 ******************************************************/
(function () {
    // Check if the setup has already been done to prevent multiple initializations
    if (window.heyflowAmplitudeLoggingInitialized) {
        return;
    }
    window.heyflowAmplitudeLoggingInitialized = true;

    const localStorageEnterUserInformation = 'enter_user_information';
    const localStorageAmplitudeDeviceId = 'amplitude_device_id';

    const getUserInformation = () => {
        return window.localStorage.getItem(localStorageEnterUserInformation) || undefined;
    };

    const getAmplitudeDeviceId = () => {
        return window.localStorage.getItem(localStorageAmplitudeDeviceId) || undefined;
    };

    const userInfo = getUserInformation();
    const { id: userUUID } = JSON.parse(userInfo || '{}');

    // Initialize Amplitude
    if (typeof amplitude !== 'undefined') {
        const initializeAmplitude = () => {
            console.log(`Initializing Amplitude...`);
            window.amplitude
                .init('990248bc7a99f441f03d934fc1376e6f', userUUID, {
                    serverZone: 'EU',
                    serverUrl: 'https://proxy.enter.de/mpltd',
                    // @ts-ignore
                    autocapture: { elementInteractions: true },
                })
                .promise.then(function () {
                const deviceId = amplitude.getDeviceId();
                if (deviceId) {
                    localStorage.setItem(localStorageAmplitudeDeviceId, deviceId);
                } else {
                    console.warn('Device ID is null. Please check the Amplitude configuration.');
                }
            });
        };

        const deviceId = getAmplitudeDeviceId();
        initializeAmplitude(); // Make sure to call the initialization function

    } else {
        console.warn('Amplitude SDK not found. Make sure it is loaded.');
        return;
    }

    function setupAmplitudeLoggingForHeyflow() {
        // Use a global variable to persist the flag across multiple invocations
        if (window.formInteractionLogged === undefined) {
            window.formInteractionLogged = false;
        }

        const surveyInfo = getSurveyInfo();
        const commonEventProperties = {
            service: 'heyflow',
            platform: 'website',
            surveyId: surveyInfo.surveyId,
            surveyName: surveyInfo.surveyName,
        };

        function logAmplitudeEvent(eventName, additionalProperties = {}) {
            if (typeof amplitude !== 'undefined' && amplitude.track) {
                const timestampUTC = Date.now(); // Get the current UTC timestamp in milliseconds
                window.amplitude.track({
                    event_type: eventName,
                    event_properties: {
                        ...commonEventProperties,
                        ...additionalProperties,
                    },
                    time: timestampUTC, // Include the timestamp in the event object
                });
            } else {
                console.warn(
                    'Amplitude not found or track method not available. Event not logged:',
                    eventName
                );
            }
        }

        function logFirstFormInteraction(event) {
            if (!window.formInteractionLogged) {
                window.formInteractionLogged = true; // Log only once per page load
                logAmplitudeEvent('Heyflow First Form Interaction', {
                    fieldsSimple: event.detail.fieldsSimple,
                });
            }
        }

        window.addEventListener('heyflow-init', (event) => {
            logAmplitudeEvent('Heyflow Init');
        });

        window.addEventListener('heyflow-change', (event) => {
            logFirstFormInteraction(event);
        });

        window.addEventListener('heyflow-exit', (event) => {
            logAmplitudeEvent('Heyflow Exit');
        });

        window.addEventListener('heyflow-submit', (event) => {
            logAmplitudeEvent('Heyflow Submit', {
                screenID: event.detail.screenID,
                screenName: event.detail.screenName,
                fields: event.detail.fields,
                fieldsSimple: event.detail.fieldsSimple,
            });
        });
    }

    function getSurveyInfo() {
        if (window.heyflow) {
            const firstKey = Object.keys(window.heyflow)[0];
            if (firstKey && window.heyflow[firstKey]?.constants) {
                const constants = window.heyflow[firstKey].constants;
                return {
                    surveyId: constants.VARIANT_ID || 'unknown',
                    surveyName: constants.FLOW_ID
                        ? constants.FLOW_ID.replace(/__/g, '-').replace(/_/g, ' ').split('-').filter((part) => part.trim() !== '').join(' ')
                        : 'unknown',
                };
            }
        }
        return {
            surveyId: 'unknown',
            surveyName: 'unknown',
        };
    }

    setupAmplitudeLoggingForHeyflow();
})();
